// export const get_date_with_user_time_zone = (date, format) => {
//   var moment = require("moment-timezone");
//   var new_date = moment.tz(date, admin_time_zone);
//   return moment(new_date).tz(user_profile.time_zone).format(format);
import moment from "moment/moment";
// import millify from "millify";
import { s3baseUrl } from "src/config/config";

// };
export const urlPatternValidation = (URL) => {
  const regex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );
  return regex.test(URL);
};
export function get_kmb_number(number) {
  return 0;
  // if (number) {
  //   return millify(number, { precision: 1 });
  // } else {
  //   return number;
  // }
}
// export const convertCurrencyToSign = (currency) => {
//   if (currency === "gbp" || currency === "GBP") {
//     return "£";
//   } else if (currency === "eur" || currency === "EUR") {
//     return "€";
//   } else if (currency === "usd" || currency === "USD") {
//     return "$";
//   } else if (currency === "AUD" || currency === "aud") {
//     return "A$";
//   } else {
//     return "";
//   }
// };
const currencySymbols = {
  AED: "د.إ",
  AFN: "؋",
  ALL: "L",
  AMD: "֏",
  ANG: "ƒ",
  AOA: "Kz",
  ARS: "$",
  AUD: "A$",
  AWG: "ƒ",
  AZN: "₼",
  BAM: "KM",
  BBD: "$",
  BDT: "৳",
  BGN: "лв",
  BHD: ".د.ب",
  BIF: "FBu",
  BMD: "$",
  BND: "$",
  BOB: "Bs.",
  BRL: "R$",
  BSD: "$",
  BTN: "Nu.",
  BWP: "P",
  BYN: "Br",
  BZD: "$",
  CAD: "C$",
  CDF: "FC",
  CHF: "CHF",
  CLP: "$",
  CNY: "¥",
  COP: "$",
  CRC: "₡",
  CUP: "$",
  CVE: "$",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "kr",
  DOP: "$",
  DZD: "د.ج",
  EGP: "£",
  ERN: "Nfk",
  ETB: "Br",
  EUR: "€",
  FJD: "$",
  FKP: "£",
  FOK: "kr",
  GBP: "£",
  GEL: "₾",
  GGP: "£",
  GHS: "₵",
  GIP: "£",
  GMD: "D",
  GNF: "FG",
  GTQ: "Q",
  GYD: "$",
  HKD: "$",
  HNL: "L",
  HRK: "kn",
  HTG: "G",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  IMP: "£",
  INR: "₹",
  IQD: "ع.د",
  IRR: "﷼",
  ISK: "kr",
  JEP: "£",
  JMD: "$",
  JOD: "د.ا",
  JPY: "¥",
  KES: "Sh",
  KGS: "с",
  KHR: "៛",
  KID: "$",
  KMF: "CF",
  KRW: "₩",
  KWD: "د.ك",
  KYD: "$",
  KZT: "₸",
  LAK: "₭",
  LBP: "ل.ل",
  LKR: "Rs",
  LRD: "$",
  LSL: "L",
  LYD: "ل.د",
  MAD: "د.م.",
  MDL: "L",
  MGA: "Ar",
  MKD: "ден",
  MMK: "K",
  MNT: "₮",
  MOP: "P",
  MRU: "UM",
  MUR: "₨",
  MVR: ".ރ",
  MWK: "MK",
  MXN: "$",
  MYR: "RM",
  MZN: "MT",
  NAD: "$",
  NGN: "₦",
  NIO: "C$",
  NOK: "kr",
  NPR: "Rs",
  NZD: "$",
  OMR: "ر.ع.",
  PAB: "B/.",
  PEN: "S/",
  PGK: "K",
  PHP: "₱",
  PKR: "₨",
  PLN: "zł",
  PYG: "₲",
  QAR: "ر.ق",
  RON: "lei",
  RSD: "дин",
  RUB: "₽",
  RWF: "FRw",
  SAR: "ر.س",
  SBD: "$",
  SCR: "₨",
  SDG: "£",
  SEK: "kr",
  SGD: "$",
  SHP: "£",
  SLE: "Le",
  SLL: "Le",
  SOS: "Sh",
  SRD: "$",
  SSP: "£",
  STN: "Db",
  SYP: "£",
  SZL: "L",
  THB: "฿",
  TJS: "ЅМ",
  TMT: "m",
  TND: "د.ت",
  TOP: "T$",
  TRY: "₺",
  TTD: "$",
  TVD: "$",
  TWD: "NT$",
  TZS: "Sh",
  UAH: "₴",
  UGX: "Sh",
  USD: "$",
  UYU: "$",
  UZS: "so'm",
  VES: "Bs.",
  VND: "₫",
  VUV: "Vt",
  WST: "T",
  XAF: "FCFA",
  XCD: "$",
  XOF: "CFA",
  XPF: "₣",
  YER: "﷼",
  ZAR: "R",
  ZMW: "ZK",
  ZWL: "$",
};

export const convertCurrencyToSign = (currency) => {
  const upperCurrency = currency?.toUpperCase();
  return currencySymbols[upperCurrency] || "";
};
export const convertPaymentPeriod = (time_period) => {
  if (time_period === "month") {
    return "Monthly";
  } else if (time_period === "year") {
    return "Yearly";
  } else if (time_period === "week") {
    return "Weekly";
  } else if (time_period === "onetime") {
    return "One Time";
  } else if (time_period === "free") {
    return "Free";
  } else if (time_period === "custom") {
    return "Custom";
  } else {
    return "";
  }
};

export function round_to_two_decimals(value) {
  // Ensure the input is a valid number (either number or string)
  const num = typeof value === "string" ? parseFloat(value) : value;

  // Check if the parsed number is a valid number
  if (isNaN(num)) {
    return null; // Return null for invalid input
  }

  // Round the number to two decimal places
  const rounded = Math.round(num * 100) / 100;

  // Return the number formatted to two decimal places
  return rounded.toFixed(2);
}

function generate_random_string(characters, length) {
  var randomString = "";
  for (var i = 0; i < length; i++) {
    var index = Math.floor(Math.random() * characters.length);
    var character = characters[index];
    randomString = randomString + character;
  }
  return randomString;
}

export const _generate_random_string = (length = 10) => {
  const string_secret_characters = "abcdefghijklmnopqrstuvwxyz";
  return generate_random_string(string_secret_characters, length);
};

export const get_date_with_user_time_zone = (
  date,
  format,
  user_time_zone,
  admin_time_zone,
  prev_format = "YYYY-MM-DD HH:mm"
) => {
  // console.log(date, "date---");
  // console.log(format, "date---");
  // console.log(user_time_zone, "date---");
  // console.log(admin_time_zone, "date---");
  if (!date || !user_time_zone || !admin_time_zone) return;
  // console.log(date, "date---");
  // console.log(format, "date---");
  // console.log(user_time_zone, "date---");
  // console.log(admin_time_zone, "date---");
  // var moment = require("moment-timezone");
  // var admin_date_time = moment.tz(date, admin_time_zone);
  // var user_date_time = admin_date_time.clone().tz(user_time_zone);
  // return user_date_time.format(format);
  // console.log(date, "avasvasvsa", admin_time_zone);
  // console.log(
  //   moment(moment.tz(date, prev_format, admin_time_zone))
  //     .tz(user_time_zone)
  //     .format(format),
  //   "dateeeeeeeeeeeeeee"
  // );
  return moment(moment.tz(date, prev_format, admin_time_zone))
    .tz(user_time_zone)
    .format(format);
};

export const TIME_ZONE_CONVERSION = (
  date,
  format,
  from_time_zone,
  to_time_zone
) => {
  // return moment(date).format(format);
  var moment = require("moment-timezone");
  let formated_date = moment(date, "YYYY-MM-DD HH:mm:ss").format(
    "YYYY-MM-DD HH:mm"
  );
  let momentObj = moment.tz(
    formated_date,
    "YYYY-MM-DD HH:mm:ss",
    from_time_zone
  );

  let final_date = moment(momentObj).tz(to_time_zone).format(format);
  return final_date;
};

export const capitalizeFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const LONG_TEXT_LIMIT = 200;
export const LONG_TEXT_LIMIT_COMMUNITY = 200;

export const getTextLength = (htmlContent) => {
  if (!htmlContent) return 0;

  // Remove HTML tags
  const textContent = htmlContent.replace(/<[^>]*>/g, "").trim();

  // Split by spaces and filter out empty strings
  const words = textContent.split(/\s+/).filter((word) => word.length > 0);

  // Return the word count
  return words.length;
};

export const BUILDER_USER_TYPE = 3;

export const handleImageExtensions = (menu) => {
  let val = menu?.map((el) => {
    return '"' + el.substring(1).toLowerCase() + '"' + ",";
  });
  let unique = val?.filter((item, i, ar) => ar.indexOf(item) === i);
  return unique;
};

export function string_avatar(name, fullname) {
  if (name) {
    const splitted = name.split(" ");
    return `${splitted[0][0]}`.toUpperCase();
  }
  // if (name) {
  //   if (fullname == "fullname") {
  //   }
  //   if (splitted.length > 1) {
  //     return `${splitted[0][0]}${splitted[1][0]}`.toUpperCase();
  //   } else if (splitted.length === 1) {
  //     return `${splitted[0][0]}`.toUpperCase();
  //   }
  // }
}

export const characterCount = (str) => {
  if (str.length > 65) {
    const new_str = str.slice(0, 65);
    return new_str + "...";
  } else {
    const get_diff = 65 - str.length;
    const spaces = " \u00A0".repeat(get_diff);
    return str + spaces;
  }
};

export const convert_time_into_string = (str) => {
  if (str) {
    const moment = require("moment");

    const isoTimestamp = str;
    const relativeTime = moment(isoTimestamp).fromNow();

    // console.log(relativeTime);
  }
};

export const get_short_string = (str, limit = 30) => {
  return str && str.length < limit ? str : str?.slice(0, limit) + "...";
};

export function get_color_from_string(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < `${string.length}`; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export const get_short_string_community = (str, limit) => {
  return str && str.length < limit ? str : str?.slice(0, limit) + "...";
};

export const stripHTML = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

export const replaceName = (text, name) => {
  // console.log(name, "okokokokjsahkdjahsdjkahskdjhsakjdh");
  const replaceString = text.replace("{Name}", name);
  return replaceString;
};

export const replace_created_for = (text) => {
  let replace_string = "";
  if (text) {
    replace_string = text.replace(/-/g, " ").replace(/_/g, " ");
  }
  return replace_string;
};
export const dd_date_format = (date) => {
  return moment(date).format("DD-MM-YYYY");
};
export const is_small_screen = () => {
  if (window.innerWidth < 500) {
    return true;
  } else {
    return false;
  }
};
export const is_md_screen = () => {
  if (window.innerWidth < 992) {
    return true;
  } else {
    return false;
  }
};

export const is_extra_large_screen = () => {
  if (window.innerWidth < 1600) {
    return true;
  } else {
    return false;
  }
};
export const is_small_screen_medium = () => {
  if (window.innerWidth < 1100) {
    return true;
  } else {
    return false;
  }
};
export const is_small_screen_small = () => {
  if (window.innerWidth < 767) {
    return true;
  } else {
    return false;
  }
};
export const getImages = (data) => {
  let galleryArray = [];
  let galleryObject = {};
  data.map((gallery, index) => {
    if (typeof gallery == "string") {
      let tempArray = JSON.parse(gallery);
      if (tempArray.length > 1) {
        tempArray.map((img) => {
          galleryObject = {
            original: s3baseUrl + img,
            thumbnail: s3baseUrl + img,
          };
          galleryArray.push(galleryObject);
        });
      } else {
        galleryObject = {
          original: s3baseUrl + tempArray,
          thumbnail: s3baseUrl + tempArray,
        };
        galleryArray.push(galleryObject);
      }
    }
  });
  return galleryArray;
};
export const getProductImages = (data) => {
  let galleryArray = [];
  let galleryObject = {};

  data.map((img) => {
    galleryObject = {
      original: img.path instanceof File ? img.path : s3baseUrl + img.path,
      thumbnail: img.path instanceof File ? img.path : s3baseUrl + img.path,
    };
    galleryArray.push(galleryObject);
  });

  return galleryArray;
};
let timer;
export const debounce = (func, timeout = 300) => {
  clearTimeout(timer);
  timer = setTimeout(() => {
    func();
  }, timeout);
};
export const formatDateAgo = (dateString) => {
  return moment(dateString).fromNow();
};
export const queryConfigurations = {
  refetchOnWindowFocus: false,
};
export const DEFAULT_IMAGE =
  "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png";

export const TimeZones = [
  "Europe/Andorra",
  "Asia/Dubai",
  "Asia/Kabul",
  "Europe/Tirane",
  "Asia/Yerevan",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba",
  "America/Argentina/Salta",
  "America/Argentina/Jujuy",
  "America/Argentina/Tucuman",
  "America/Argentina/Catamarca",
  "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan",
  "America/Argentina/Mendoza",
  "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia",
  "Pacific/Pago_Pago",
  "Europe/Vienna",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Broken_Hill",
  "Australia/Brisbane",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Perth",
  "Australia/Eucla",
  "Asia/Baku",
  "America/Barbados",
  "Asia/Dhaka",
  "Europe/Brussels",
  "Europe/Sofia",
  "Atlantic/Bermuda",
  "Asia/Brunei",
  "America/La_Paz",
  "America/Noronha",
  "America/Belem",
  "America/Fortaleza",
  "America/Recife",
  "America/Araguaina",
  "America/Maceio",
  "America/Bahia",
  "America/Sao_Paulo",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Santarem",
  "America/Porto_Velho",
  "America/Boa_Vista",
  "America/Manaus",
  "America/Eirunepe",
  "America/Rio_Branco",
  "America/Nassau",
  "Asia/Thimphu",
  "Europe/Minsk",
  "America/Belize",
  "America/St_Johns",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "Indian/Cocos",
  "Europe/Zurich",
  "Africa/Abidjan",
  "Pacific/Rarotonga",
  "America/Santiago",
  "America/Punta_Arenas",
  "Pacific/Easter",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "America/Bogota",
  "America/Costa_Rica",
  "America/Havana",
  "Atlantic/Cape_Verde",
  "America/Curacao",
  "Indian/Christmas",
  "Asia/Nicosia",
  "Asia/Famagusta",
  "Europe/Prague",
  "Europe/Berlin",
  "Europe/Copenhagen",
  "America/Santo_Domingo",
  "Africa/Algiers",
  "America/Guayaquil",
  "Pacific/Galapagos",
  "Europe/Tallinn",
  "Africa/Cairo",
  "Africa/El_Aaiun",
  "Europe/Madrid",
  "Africa/Ceuta",
  "Atlantic/Canary",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Atlantic/Stanley",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Pacific/Kosrae",
  "Atlantic/Faroe",
  "Europe/Paris",
  "Europe/London",
  "Asia/Tbilisi",
  "America/Cayenne",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Godthab",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "Europe/Athens",
  "Atlantic/South_Georgia",
  "America/Guatemala",
  "Pacific/Guam",
  "Africa/Bissau",
  "America/Guyana",
  "Asia/Hong_Kong",
  "America/Tegucigalpa",
  "America/Port-au-Prince",
  "Europe/Budapest",
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Jayapura",
  "Europe/Dublin",
  "Asia/Jerusalem",
  "Asia/Kolkata",
  "Indian/Chagos",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Atlantic/Reykjavik",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Amman",
  "Asia/Tokyo",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Tarawa",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Qostanay", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Atyrau",
  "Asia/Oral",
  "Asia/Beirut",
  "Asia/Colombo",
  "Africa/Monrovia",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Europe/Riga",
  "Africa/Tripoli",
  "Africa/Casablanca",
  "Europe/Monaco",
  "Europe/Chisinau",
  "Pacific/Majuro",
  "Pacific/Kwajalein",
  "Asia/Yangon",
  "Asia/Ulaanbaatar",
  "Asia/Hovd",
  "Asia/Choibalsan",
  "Asia/Macau",
  "America/Martinique",
  "Europe/Malta",
  "Indian/Mauritius",
  "Indian/Maldives",
  "America/Mexico_City",
  "America/Cancun",
  "America/Merida",
  "America/Monterrey",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Chihuahua",
  "America/Ojinaga",
  "America/Hermosillo",
  "America/Tijuana",
  "America/Bahia_Banderas",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Africa/Maputo",
  "Africa/Windhoek",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Africa/Lagos",
  "America/Managua",
  "Europe/Amsterdam",
  "Europe/Oslo",
  "Asia/Kathmandu",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "America/Panama",
  "America/Lima",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "Pacific/Gambier",
  "Pacific/Port_Moresby",
  "Pacific/Bougainville",
  "Asia/Manila",
  "Asia/Karachi",
  "Europe/Warsaw",
  "America/Miquelon",
  "Pacific/Pitcairn",
  "America/Puerto_Rico",
  "Asia/Gaza",
  "Asia/Hebron",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "Pacific/Palau",
  "America/Asuncion",
  "Asia/Qatar",
  "Indian/Reunion",
  "Europe/Bucharest",
  "Europe/Belgrade",
  "Europe/Kaliningrad",
  "Europe/Moscow",
  "Europe/Simferopol",
  "Europe/Kirov",
  "Europe/Astrakhan",
  "Europe/Volgograd",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Europe/Samara",
  "Asia/Yekaterinburg",
  "Asia/Omsk",
  "Asia/Novosibirsk",
  "Asia/Barnaul",
  "Asia/Tomsk",
  "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk",
  "Asia/Irkutsk",
  "Asia/Chita",
  "Asia/Yakutsk",
  "Asia/Khandyga",
  "Asia/Vladivostok",
  "Asia/Ust-Nera",
  "Asia/Magadan",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Kamchatka",
  "Asia/Anadyr",
  "Asia/Riyadh",
  "Pacific/Guadalcanal",
  "Indian/Mahe",
  "Africa/Khartoum",
  "Europe/Stockholm",
  "Asia/Singapore",
  "America/Paramaribo",
  "Africa/Juba",
  "Africa/Sao_Tome",
  "America/El_Salvador",
  "Asia/Damascus",
  "America/Grand_Turk",
  "Africa/Ndjamena",
  "Indian/Kerguelen",
  "Asia/Bangkok",
  "Asia/Dushanbe",
  "Pacific/Fakaofo",
  "Asia/Dili",
  "Asia/Ashgabat",
  "Africa/Tunis",
  "Pacific/Tongatapu",
  "Europe/Istanbul",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Asia/Taipei",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Wake",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "America/Caracas",
  "Asia/Ho_Chi_Minh",
  "Pacific/Efate",
  "Pacific/Wallis",
  "Pacific/Apia",
  "Africa/Johannesburg",
];
export const show_proper_words = (text) => {
  let replace_string = "";
  if (text) {
    // Replace hyphens and underscores with spaces
    replace_string = text.replace(/[-_]/g, " ");
    // Capitalize the first letter of every word
    replace_string = replace_string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return replace_string;
};
export default function formatAmount(amount) {
  const integerAmount = parseInt(amount, 10);

  // if (isNaN(integerAmount) || !Number.isInteger(integerAmount)) {
  //   throw new Error("Input must be a valid integer or number");
  // }

  const floatAmount = parseFloat(integerAmount);

  const formattedAmount = floatAmount.toFixed(2);

  return formattedAmount;
}
let prompts = [
  {
    name: "dashboard",
    content:
      "Explore detailed analytics for Sales, Subscribers, Community Engagement, Transactions, and Recent Messages directly from your Dashboard. Gain valuable insights at a glance!",
  },
  {
    name: "manage_programmes",
    content:
      "Manage and tailor Programmes, Lessons, Drip Sequences, and Recordings all in one place. Your centralized hub for streamlined programme control.",
  },
  {
    name: "training_programmes",
    content:
      "Manage and customize Training Programmes, designed to deliver comprehensive training material  and optimize training content to enhance learner participation.",
  },
  {
    name: "courses",
    content:
      "Organize and tailor individual Courses, focusing on structured learning paths and assessments to ensure knowledge retention and skill development for participants.",
  },
  {
    name: "website_pages",
    content:
      "Effortlessly manage Landing Pages, Funnels and Payment Plans from the Website Pages section. Streamline your online presence and financial control with ease.",
  },
  {
    name: "domain_setting",
    content:
      "This is where you set the domain for hosting your web pages and as the sender domain for emails. You can manage your sender name in the email configuration.",
  },
  {
    name: "stripe",
    content:
      "This is for handling payments from subscribers. You need to manage your Stripe account details here.",
  },
  {
    name: "paypal",
    content:
      "This section is for managing your PayPal account details. Use this area to configure and oversee your PayPal integration for handling payments from subscribers. Ensure that your PayPal account information is accurate and up to date to facilitate smooth transactions and manage subscription payments effectively.",
  },
  {
    name: "active_campaign_setting",
    content:
      "This is for handling Active Compaign. You need to manage your Active Compaign details here.",
  },
  {
    name: "zoom_setting",
    content:
      "This is for handling Zoom Meetings. You need to manage your Zoom Setting details here.",
  },
  {
    name: "cookie_setting",
    content:
      "This is for handling Cookie Settings. You need to manage your Cookie Setting details here.",
  },
  {
    name: "website",
    content:
      "Effortlessly manage Website Pages, Payment Plans, Page Settings and Taxes flow from the Website Pages section. Streamline your online presence and financial control with ease.",
  },
  {
    name: "taxes",
    content:
      "Manage your taxes and arrange them accordily with your needs through this section.",
  },
  {
    name: "community",
    content:
      "Craft a thriving Community Feed and efficiently manage your community interactions—all in one place. Take control and foster engagement in the Community Tab.",
  },
  {
    name: "communities",
    content:
      "Strategically grow your community empire. Build and manage multiple communities with unified, intelligent tools for optimized engagement and expansion.",
  },
  {
    name: "browse_community",
    content:
      "Browse through thriving communities, each offering unique discussions, events, and opportunities. Join the conversation and be part of something bigger!",
  },
  {
    name: "members",
    content:
      "Take charge of member access, monitor activity, and easily assign programmes manually—all within the Members Tab.",
  },
  {
    name: "payments",
    content:
      "Monitor Payment Transactions and Affiliate Transactions in the Payment Tab. Keep a close eye on financial activities with ease.",
  },
  {
    name: "portal",
    content:
      "Unlock Event Management and seamless Live Event Hosting capabilities within the Portals Tab. Your gateway to orchestrating and hosting engaging events effortlessly.",
  },
  {
    name: "calendar",
    content:
      "Streamline your event planning and group coordination in the Calendar Tab. Effectively manage calendar events and related groups for seamless organization.",
  },
  {
    name: "payment_plan",
    content:
      "Create your Payment Plans here.These Plans can be attached against website pages in Plan automation.",
  },
  {
    name: "plan_default_configuration",
    content:
      "Defines the foundational setup, including payment gateway integration, currency selection, portal customization, program management, and agreement options for streamlined operations.",
  },
  {
    name: "mailchimp-setting",
    content:
      "Integrates email marketing capabilities by connecting the platform with Mailchimp for managing campaigns, subscribers, and communication automation.",
  },
  {
    name: "seo_setting",
    content:
      " Optimizes the platform's meta tags, keywords, and search engine visibility to enhance online discoverability.",
  },
  {
    name: "client_dashboard_layout",
    content: "This is where you set the layout of client dashboard. ",
  },
  {
    name: "automation",
    content:
      "Effortlessly automate the Programmes Navbar and Payment Plans from this Automation Tab. Simplify navigation in programme management and enhance efficiency of Website Pages .",
  },
  {
    name: "automate_navbar",
    content:
      "Create a default navbar that will be automatically assigned to programmes, and also manually update the navbar of individual programmes from this section.",
  },
  {
    name: "page_automation",
    content:
      "Automate the plan assigning process to the website pages, you can also add programmes and portals against the plans.",
  },
  {
    name: "programmes",
    content:
      "Efficiently oversee membership programmes, programme lessons, documents, sections, categories, reviews, lesson recordings,lesson documents and more from the Programme Tab. Your centralized hub for comprehensive programme management.",
  },
  {
    name: "drip_sequence",
    content:
      "With Drip, customize the release schedule for your published curriculum programme. Set specific access intervals, allowing members to engage with the programme gradually over a chosen number of days. ",
  },
  {
    name: "recordings",
    content:
      "Include Programme Recordings here, complete with audio, video and short descriptions for each recording.",
  },
  {
    name: "recording_categories",
    content:
      "Utilize Recording Categories to organize and add Programme Recordings seamlessly. Enhance the organization of your programme recordings by assigning them to specific recording categories.",
  },
  {
    name: "configuration",
    content:
      "You can add the Lock configuration of Programmes for Client Portal",
  },
  {
    name: "content_onfiguration",
    content:
      "From here Dummy Content and the Header Contenet of Programmes can be added for Client Portal",
  },
  {
    name: "calendar_events",
    content:
      "Define calendar events for clients here. These events are rule-based configurations that dictate specific days on designated calendars, triggering notifications for clients.",
  },
  {
    name: "groups",
    content:
      "Create and assign groups for Calendar events right here. Easily manage your events by associating them with specific groups during the event creation process.",
  },
  {
    name: "pods",
    content:
      " Represents modular and customizable content units or features designed to structure and organize platform functionalities efficiently.",
  },
  {
    name: "coach_setting",
    content:
      "Take charge of default data for coaches in one place. This includes their default programme, metadata, branding, and payment currency. Any changes made here will impact what coaches see as defaults.",
  },
  {
    name: "email_configuration",
    content:
      "This handles email settings like templates and logos. Changes affect the appearance of emails sent to both clients and coaches.",
  },

  {
    name: "email_templates",
    content:
      "This is your hub for defining the appearance of email templates. Any modifications made here will reflect in the content of emails sent to clients, ensuring a cohesive and personalized communication experience.",
  },
  {
    name: "email_subscription",
    content:
      "This is where you can see your unsubscribe emails and can subscribe them again",
  },
  {
    name: "page_settings",
    content:
      "This is where you set the domain for hosting your web pages, manage your Active Compaign details here ",
  },
  {
    name: "page_settings",
    content:
      "This is where you set the domain for hosting your web pages, manage your Active Compaign details here ",
  },
  {
    name: "stripe",
    content: "This is where you can manage your stripe account details.",
  },
  {
    name: "coach_dummy_content",
    content: "This is where you set dummy Content for Coach",
  },
  {
    name: "client_dummy_content",
    content: "This is where you set dummy Content for Client",
  },
  {
    name: "analytics",
    content:
      "It involves the use of statistical methods, algorithms, and tools to analyze raw data, transforming it into actionable information for businesses, research, and various industries",
  },
  {
    name: "analytics_overview",
    content:
      "It involves the total users, active users, new users, and average engag stats.",
  },
  {
    name: "analytics_realtime_report",
    content:
      "Real-time analytics provides insights into the number of users active within the last 5 minutes and 30 minutes, offering immediate data to monitor engagement and activity trends.",
  },
  {
    name: "analytics_event",
    content:
      "Tracks and analyzes user interactions with specific actions or events on a platform, such as clicks, downloads, or video plays.",
  },
  {
    name: "analytics_demographic_report",
    content:
      "Provides insights into user characteristics like age, gender, location, and interests to better understand the audience.",
  },
  {
    name: "analytics_funnel_exploration",
    content:
      "Funnel exploration visualizes user steps toward a goal, highlighting drop-offs to optimize engagement and conversions.",
  },
  {
    name: "analytics_platform_details",
    content:
      "Offers data on the devices, operating systems, and browsers users utilize to access the platform.",
  },
  // {
  //   name: "manage_domains",
  //   content:
  //     "This is where you set the domain for hosting your web pages and as the sender domain for emails. You can manage your sender name in the email configuration.",
  // },
  // {
  //   name: "stripe",
  //   content:
  //     "This is for handling payments from subscribers. You need to manage your Stripe account details here.    ",
  // },

  // {
  //   name: "active_campaign_settings",
  //   content:
  //     "This is for handling Active Compaign. You need to manage your Active Compaign details here.    ",
  // },

  {
    name: "invoice_setting",
    content:
      "This section pertains to the appearance of invoices. Any changes made here will directly impact the visual aspects of invoices sent when payments are successful.",
  },
  {
    name: "client_portal_brand_setting",
    content:
      "This configures the client portal, including metadata, background images and text. Changes affect how the client portal looks and what information is displayed.",
  },

  {
    name: "client_portal_content_setting",
    content:
      "These configurations set background images and text. Changes to client content configuration will affect clients portal.",
  },
  {
    name: "support_ticket",
    content:
      "Utilize the Support Ticket Feature to initiate and manage interactions between clients and support teams. Create support tickets seamlessly, empowering the system to handle and address your specific needs.",
  },
  {
    name: "support_team",
    content:
      "Assign a dedicated team here to handle tickets originating from clients, serving the dual role of managing support requests and acting as the coach. Streamline your support and coaching functions within this integrated system.",
  },
  {
    name: "department",
    content:
      "Integrate the Support Team into the Privileges-Based System from this section. Define access levels for team members by specifying their privileges, ensuring streamlined control over department-specific functionalities.",
  },
  {
    name: "auto_responded_messages",
    content:
      "Craft an automated message here that will be instantly displayed to clients when they submit a support ticket. Enhance client communication and set expectations effectively with this personalized touch.",
  },
  {
    name: "coach_support_ticket",
    content:
      "Add support tickets for the admin directly from this section, addressing any support-related inquiries promptly and efficiently.",
  },
  {
    name: "shop",
    content: "Shop where you find products, discounts, categories, and more.",
  },
  {
    name: "shop_category",
    content:
      "Browse through various categories to find products tailored to your needs. Easily manage, add, or edit categories to organize your shop efficiently.",
  },
  {
    name: "shop_product",
    content:
      "Discover and manage products effortlessly. Add, edit, or update details, set prices, and organize your shop to offer the best to your customers.",
  },
  {
    name: "shop_section",
    content:
      "Create and manage product sections seamlessly. Organize items into categories, add details, and showcase your products for an enhanced shopping experience.",
  },
  {
    name: "shop_setting",
    content:
      "Enable favorite products in settings with an option to allow users to mark and manage their favorites.",
  },
  {
    name: "shop_payment_configuration",
    content:
      "Defines the foundational setup, including payment gateway integration, currency selection options for streamlined operations.",
  },

  {
    name: "shop_coupon",
    content: "Create and manage product coupons to boost sales.",
  },
];

export function findContentByName(name) {
  for (let i = 0; i < prompts.length; i++) {
    if (prompts[i].name === name) {
      return prompts[i].content;
    }
  }

  return null;
}

export function calculateMonthDateRange() {
  const currentDate = new Date();

  // Calculate start and end dates for the last 30 days
  const presentMonthStart = new Date(currentDate);
  presentMonthStart.setDate(currentDate.getDate() - 29); // 30 days ago

  const presentMonthEnd = new Date(); // Today

  return { presentMonthStart, presentMonthEnd };
}

export function calculateWeekDateRange() {
  const currentDate = new Date();

  // Calculate start and end dates for the last seven days
  const presentWeekStart = new Date(currentDate);
  presentWeekStart.setDate(currentDate.getDate() - 6); // Seven days ago

  const presentWeekEnd = new Date(); // Today

  return { presentWeekStart, presentWeekEnd };
}

export function calculateDayDateRange() {
  const currentDate = new Date();

  // Calculate start and end dates for the previous day
  const previousDayStart = new Date(currentDate);
  previousDayStart.setDate(currentDate.getDate() - 1); // Previous day
  previousDayStart.setHours(0, 0, 0, 0); // Set to the start of the day

  const previousDayEnd = new Date(previousDayStart);
  previousDayEnd.setHours(23, 59, 59, 999); // Set to the end of the day

  // Calculate start and end dates for the present day
  const presentDayStart = new Date();
  presentDayStart.setHours(0, 0, 0, 0); // Set to the start of the day

  const presentDayEnd = new Date();
  presentDayEnd.setHours(23, 59, 59, 999); // Set to the end of the day

  return { presentDayStart, presentDayEnd };
  // return { previousDayStart, previousDayEnd,presentDayStart, presentDayEnd };
}

export const get_extension_of_file = (string, ext1 = null, ext2 = null) => {
  const last_digits = string.slice(-5);
  let result = false;
  if (last_digits.includes(ext1)) {
    result = true;
  }

  if (ext2 && last_digits.includes(ext2)) {
    result = true;
  }

  return result;
};
export function generateRandomID(length = 5) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let id = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters.charAt(randomIndex);
  }
  return id;
}

export const replaceUserNamesWithHTML = (string, users, editable) => {
  // Normalize line breaks
  string = string.replace(/\r\n/g, "\n");

  function generateUserHTML(user) {
    const random_id = user._id + "-" + generateRandomID(16);
    return `<span spellCheck="false" contentEditable="false" class="mentioned-name" id="${random_id}">${user.first_name} ${user.last_name}</span>`;
  }

  if (users) {
    // Sort users based on offset in descending order
    users.sort((a, b) => b.offset - a.offset);

    // Replace mentions
    users.forEach((user) => {
      const { offset, length } = user;
      const userHTML = generateUserHTML(user);
      string =
        string.slice(0, offset) + userHTML + string.slice(offset + length);
    });

    if (editable) {
      // Replace newlines with <br> tags for editable content
      string = string.replace(/\n/g, "<br>");
    }
  }

  return string;
};
export const post_description = (str, limit = 500) => {
  if (!str) return "";
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, "text/html");
  function extractText(node, limit) {
    let result = "";
    let charCount = 0;
    function traverse(node) {
      if (charCount >= limit) return;
      if (node.nodeType === Node.TEXT_NODE) {
        const remainingChars = limit - charCount;
        const text = node.textContent.slice(0, remainingChars);
        result += text;
        charCount += text.length;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        result += `<${node.nodeName.toLowerCase()}`;
        Array.from(node.attributes).forEach((attr) => {
          result += ` ${attr.name}="${attr.value}"`;
        });
        result += ">";

        Array.from(node.childNodes).forEach((childNode) => traverse(childNode));
        result += `</${node.nodeName.toLowerCase()}>`;
      }
    }
    traverse(node);
    return result;
  }

  const extractedText = extractText(doc.body, limit);
  return extractedText + (extractedText.length < str.length ? "..." : "");
};
